.navbar {
  position: fixed;
  top: 0;
  color: white;
  background-color: transparent;
}

.navbar.transparent {
  background-color: transparent !important;
}

.navbarMobile {
  display: none;
  display: flex;
  position: fixed;
  width: 100%;
}

.navbarMobile .navLink {
  padding: 12px 16px;
}

.navbarMobile .navLink:not(:first-child) {
  border-top: 1px solid #414752;
}

.navbarMobile.light .navLink:not(:first-child) {
  border-color: #4d95ff;
}

.navbarMobile.white .navLink:not(:first-child) {
  border-color: #cddffd;
}

.navbarMobile.dark {
  background-color: #00152a;
  color: white;
}

.navbarMobile.light {
  background-color: #0067ff;
  color: white;
}

.navbarMobile.white {
  background-color: #f4f6ff;
  color: #0a0a0a;
}

.navbarMobile.light .closeButton {
  color: white;
}

.navbarMobile.white .closeButton {
  color: #0a0a0a;
}

.navbarMobile.light .mobileLogo path,
.navbarMobile.dark .mobileLogo path {
  fill: white;
}

.navbar.dark {
  background-color: #0a0a0a;
}

.navbar.white {
  background-color: #f4f6ff;
  color: #0a0a0a;
  border-color: #cddffd;
}

.navbar.light {
  background-color: #0067ff;
}

.navbar.dark path,
.navbar.light path {
  fill: white;
}

.navbar .navLink:hover {
  border-radius: 100px;
  background-color: rgba(205, 223, 253, 0.2);
}

.navbarMobile .link:hover,
.navbar .navButton:hover {
  border-radius: 8px;
  background-color: rgba(205, 223, 253, 0.2);
}

.navLink.isActive {
  background-color: #cddffd !important;
  padding: 4px 16px;
  border-radius: 100px;
  color: #0a0a0a;
}

@media (max-width: 1439px) {
  .closeButton {
    padding: 2px;
    width: 32px;
    height: 32px;
    color: white;
  }

  .navbarContent {
    align-items: center;
    column-gap: 16px;
  }
}

@media (max-width: 959px) {
  .navbar {
    height: 80px;
  }

  .navbarMobile {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 567px) {
  .navbar {
    padding: 8px 16px;
    height: 52px;
  }

  .navbarMobile {
    padding: 8px 16px 16px;
  }

  .mobileLogo {
    width: 99px;
  }
}
